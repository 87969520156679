body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/images/background.png");
  background-repeat: repeat;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.box{
  width:90vw;
  height: 90vh;
  margin:auto;
  border-radius: 1%;
  
  
}
.groupCards{
  width: 90vw;
  margin:auto;
  margin-top: -6%;
}
.intro{
/*background-image: url("./images/reading.jpg");*/
background-repeat: repeat;
}
.story{
  text-align: center;
  
  
}
#logo{
  margin-top: -37%;
  margin-left: 29%;
  filter: drop-shadow(0 0 15px white);
}
#logobox{
  padding-top: 2%;
  background-color: white;
  
}

#storyWords{
  color: rgb(181,106,119);
  position: relative;
  justify-content: center;
  text-align: center;
  background-color: white;
  background-size: cover;
  margin-left: -28px;
  padding-top: 5%;
  padding-bottom: 4%;
  border-radius: 0px 5px 5px 0px;
}
#missionWords{
  color: rgb(181,106,119);
  position: relative;
  justify-content: center;
  text-align: center;
  background-color: white;
  background-size: cover;
  margin-left: 15px;
  padding-top: 15%;
  padding-bottom: 10.4%;
  border-radius: 5px 0px 0px 5px;
  
}
.storyPic{
  background-image: url("./images/table.jpg");
  background-size: cover;
  margin-left: 14px;
  border-radius: 10px 10px 10px 10px;
}
.historyPic{
  background-image: url("./images/girls.jpg");
  background-size: cover;
  margin-left: 14px;
  border-radius: 10px 10px 10px 10px;
}
.missionPic{
  background-image: url("./images/table.jpg");
  background-size: cover;
  margin-left: -28px;
  border-radius: 0px 5px 5px 0px;
}
